<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <!-- pf_percentage_employee -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Employee Percentage" vid="pf_percentage_employee" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="pf_percentage_employee">
                <template v-slot:label>
                  {{ $t('teaGarden.employee_percentage') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="pf_percentage_employee"
                  v-model="data.pf_percentage_employee"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- warker_weekly_percentage -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Weekly Laborer Percentage" vid="stuff_weekly_percentage" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="stuff_weekly_percentage">
                <template v-slot:label>
                  {{ $t('teaGarden.stuff_weekly_percentage') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="stuff_weekly_percentage"
                  v-model="data.pf_percentage_stuff"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- staff_monthly_percentage -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Staff Percentage" vid="stuff_percentage" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="stuff_percentage">
                <template v-slot:label>
                  {{ $t('teaGarden.stuff_percentage') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="stuff_percentage"
                  v-model="data.pf_monthly_staff"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- warker_monthly_percentage -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Monthly Laborer Percentage" vid="warker_monthly_percentage" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="warker_monthly_percentage">
                <template v-slot:label>
                  {{ $t('teaGarden.warker_monthly_percentage') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="warker_monthly_percentage"
                  v-model="data.pf_monthly_warker"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
           <!-- union_subcription -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Union subscription" vid="union_subcription" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="union_subcription">
                <template v-slot:label>
                  {{ $t('teaGarden.union_subcription') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="union_subcription"
                  v-model="data.union_subcription"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- effective_from  -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Effective Date" vid="effective_from" rules="required">
              <b-form-group
                class="row"
                label-for="effective_from"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGarden.effective_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="effective_from"
                  v-model="data.effective_from"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :locale="currentLocale"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- employee types  -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6"></b-col>
          </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { providentFundStore, providentFundUpdate } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.gdnTaskList()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    employee_types () {
      return this.$store.state.TeaGardenService.commonObj.employeeType
    },
    headAccountType () {
      return this.$store.state.TeaGardenService.commonObj.headAccountType
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${providentFundUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, providentFundStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    gdnTaskList () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
